.skills-section{
    .swiper{
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .swiper-wrapper{
        display: flex;
        align-items: center;
    }
    img{
        filter: saturate(0.1);
        &:hover
{
            filter: saturate(100%);
        }
        object-fit: contain;
        transition-property: all;
        transition-duration: 700ms;
        transition-delay: 150ms;
        transition-timing-function: ease-in-out;
    }
}