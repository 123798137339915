* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.main-section {
  .home-content{
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-bottom: 100px;
  @media (max-width:768px) {
    padding-bottom: 0;
    
  }
  > span {
    font-size: 16px;
    letter-spacing: 4px;
    font-weight: 800;
    color: #3B3BFF;

  }
  h1 {
    text-align: center;
    font-weight: 800;
    font-size: 60px;
    @media (max-width:768px) {
      font-size: 45px;
      
    }
  }
  h2 {
    text-align: center;
font-weight: 800;
    span {
      padding-left: 5px;
      text-decoration: underline;
      color: #3B3BFF;
      .react-rotating-text-cursor{
        font-weight: 300;
      }
    }
  }

  }
  
  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    background-color:#ECF1FF;
    z-index: -1;


  }
 
}
