.projects-section {
  .card {
    border: none;
    margin-bottom: 30px;
    .card-title{
      margin: 0;
    }
    a:hover
{
      color: #3B3BFF;
      position: relative;
      .overlay {
        display: flex !important;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        transition: all 0.3s ease;
        position: absolute;
        background: #ECF1FF;
        opacity: 0.9;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        content: "";
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
}
    }
   
}
}
