* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  --bs-navbar-toggler-focus-width: 0px;
}
.top-navbar {
  @media (max-width: 992px) {
    background-color: white;
  }
  .navbar-collapse {
    flex-grow: 0;
  }
  .navbar-nav {
    @media (max-width: 992px) {
      padding-top: 20px;
      font-weight: 600;
      a {
        padding-left: 20px;
        &:hover {
          background-color: #3b3bff;
          color: white !important;
        }
      }
    }
    a {
      color: black;
      font-weight: 500;
      &:hover {
        color: #3b3bff;
      }
    }
    // .nav-link.active {
    //   color: #3b3bff;
    // }
  }

  .navbar-brand {
    display: inline-block;
    font-weight: 900;
    font-size: 26px;
    position: relative;
    z-index: 0;
    margin-right: 1rem;
    line-height: inherit;
    white-space: nowrap;
    letter-spacing: 2px;
    span {
      color: white;
    }

    @media (max-width: 768px) {
      margin-left: 20px;
    }
  }
  .navbar-brand:after {
    position: absolute;
    top: 50%;
    left: -20px;
    width: 45px;
    height: 45px;
    content: "";
    background-color: #3b3bff;
    z-index: -1;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  .navbar-toggler {
    border: none;
    font-size: 1.1rem;
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27black%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
    }
  }
}
