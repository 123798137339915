.contact-section {
display: flex;
flex-direction: column;
gap: 20px;
align-items: center;
justify-content: center;
p{
    text-align: center;
}
  .contact-content {  
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 30px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: 20px;
        }
        background-color: #3b3bff;
        color: white;
        padding: 20px;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
      }
    
  }
}
