.scroll-to-top{
    position: fixed;
    right: 40px;
    transition: bottom 0.3s ease-in-out;
    border: 1px solid #3B3BFF;
    padding: 20px;
    border-radius: 40px;
    color: #27E224;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
    z-index: 50;
    display: flex;
    @media (max-width:768px) {
        padding: 10px;
        right: 30px;
        svg{
            font-size: 12px;
        }
        
    }
}